<template>
  <div>
    <v-layout wrap py-3 justify-center pa-3 pt-8>
      <v-flex xs12 py-4t text-center pt-8>
        <span
          class="bold"
          style="
            border-bottom: 5px solid #ff8533;
            font-size: 30px;
            padding-left: 20px;
            padding-right: 20px;
          "
        >
          Infrastructure</span
        >
      </v-flex>
      <v-flex xs12 lg8>
        <v-layout wrap pt-8>
          <v-flex xs12>
            <span class="bold" style="font-size: 18px; color: #2c3e50"
              >Ground-Floor</span
            >
          </v-flex>
          <v-flex xs10 class="bold"> ROOM </v-flex>
          <v-flex xs2 class="bold"> COUNT </v-flex>
          <v-flex xs12>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs10 class="regular" py-3> LECTHURE ROOM </v-flex>
          <v-flex xs2 py-3> 1 </v-flex>
          <v-flex xs10 class="regular" py-3> PRINCIPAL ROOM </v-flex>
          <v-flex xs2 py-3> 1 </v-flex>
          <v-flex xs10 class="regular" py-3> OFFICE ROOM </v-flex>
          <v-flex xs2 py-3> 1 </v-flex>
          <v-flex xs10 class="regular" py-3> STAFF ROOM </v-flex>
          <v-flex xs2 py-3> 1 </v-flex>
          <v-flex xs10 class="regular" py-3> TOILET </v-flex>
          <v-flex xs2 py-3> 5 </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12>
            <span class="bold" style="font-size: 18px; color: #2c3e50"
              >FIRST FLOOR</span
            >
          </v-flex>
          <v-flex xs10 class="bold"> ROOM </v-flex>
          <v-flex xs2 class="bold"> COUNT </v-flex>
          <v-flex xs12>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs10 class="regular" py-3> LECTHURE ROOM </v-flex>
          <v-flex xs2 py-3> 1 </v-flex>
          <v-flex xs10 class="regular" py-3> SCIENCE LAB</v-flex>
          <v-flex xs2 py-3> 1 </v-flex>
          <v-flex xs10 class="regular" py-3> IT LAB </v-flex>
          <v-flex xs2 py-3> 1 </v-flex>
          <v-flex xs10 class="regular" py-3> TOILET</v-flex>
          <v-flex xs2 py-3> 2 </v-flex>
        </v-layout>
        <v-layout wrap pb-7>
          <v-flex xs12>
            <span class="bold" style="font-size: 18px; color: #2c3e50"
              >SECOND FLOOR</span
            >
          </v-flex>
          <v-flex xs10 class="bold"> ROOM </v-flex>
          <v-flex xs2 class="bold"> COUNT </v-flex>
          <v-flex xs12>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs10 class="regular" py-3> LIBRARY </v-flex>
          <v-flex xs2 py-3> 1 </v-flex>
          <v-flex xs10 class="regular" py-3> ASSEMBLY HALL</v-flex>
          <v-flex xs2 py-3> 1 </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
     <v-layout wrap  >
            <v-flex xs12 py-4t text-center pt-7 pt-lg-12>
              <span class="bold" style="border-bottom:5px solid #ff8533 ;font-size:30px;padding-left:20px;padding-right:20px"> Labs and Library</span>
            </v-flex>
            <v-flex xs12>
            <v-layout wrap pa-3 pa-lg-12>
                <v-flex md3 v-for="(item,i) in images" :key="i">
                    <v-img  :src="require('../assets/images/gallery/' +item.image)">
                    </v-img>
                </v-flex>
            </v-layout>
            </v-flex>
        </v-layout>

  </div>
</template>
<script>
export default {
    data()
    {
        return{
            images:[
                // {
                //    image:"a0.jpg"
                // },
                
                //  {
                //    image:"a3.jpg"
                // },
                //  {
                //    image:"a4.jpg"
                // },
                 {
                   image:"a5.jpg"
                },
                 {
                   image:"a6.jpg"
                },
                 {
                   image:"a7.jpg"
                },
                 {
                   image:"a8.jpg"
                },
                 {
                   image:"a9.jpg"
                },
                 {
                   image:"a10.jpg"
                },
                 {
                   image:"a12.jpg"
                },
                 {
                   image:"a13.jpg"
                },
                
            ]
        }
    }
}
</script>